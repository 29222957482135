import { sso } from "roboflex-thalamus-sso-lib";
import { api } from "roboflex-thalamus-request-handler";

const link = "https://portal.thalamus.ind.br/#/";
const linkHomologacao = "http://portal-homologacao.thalamus.ind.br/#/";

const moduloService = {
  buscarModulos: async () => {
    const usuarioLogado = sso.getUsuarioLogado();

    if (!usuarioLogado || !usuarioLogado.id) {
      throw new Error("Usuário ainda não carregado");
    }

    const resp = await api.post("funcionalidade/cards/usuario", {
      usuario_id: usuarioLogado.id,
    });

    return resp.data;
  },
  linkPortal() {
    return process.env.NODE_ENV == "staging" || process.env.NODE_ENV == "development" ? linkHomologacao : link;
  },
};

export default moduloService;
