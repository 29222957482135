<template>
  <HeaderComponent :modulXeName="$moduleName" :buildMode="envMode" />
  <body class="inicial">
    <main>
      <section>
        <div class="titulo">
          <div class="container margem alinha-centro">
            <h2>Selecione o módulo</h2>
          </div>
        </div>
        <div class="margem container">
          <div>
            <LoadingHelper v-show="isLoading"></LoadingHelper>
          </div>
          <div v-if="dataLoaded" class="grid-4">
            <a v-for="modulo in menus" :key="modulo.id" :href="modulo.URL" class="card bloco">
              <figure>
                <img :src="getImageSrc(modulo.caminho_img)" />
                <figcaption class="margem abs">{{ modulo.nome }}</figcaption>
              </figure>
            </a>
          </div>
        </div>
      </section>
    </main>
  </body>
</template>

<script>
import moduloService from "@/services/moduloService";
import { HeaderComponent } from "roboflex-thalamus-componentes-vue3-lib";
import LoadingHelper from "@/components/helpers/LoadingHelper.vue";

export default {
  name: "HomeView",
  components: {
    HeaderComponent,
    LoadingHelper,
  },
  data() {
    return {
      menus: [],
      dataLoaded: false,
      isLoading: true,
      envMode: process.env.NODE_ENV,
    };
  },
  methods: {
    getImageSrc(imagePath) {
      try {
        return require(`../assets/img/${imagePath}`);
      } catch (e) {
        console.error("Imagem não encontrada:", imagePath);
        return "";
      }
    },
    async carregarModulos() {
      try {
        const resp = await moduloService.buscarModulos();
        this.menus = resp;
      } catch (error) {
        console.error("Erro ao buscar módulos:", error);
      } finally {
        this.isLoading = false;
        this.dataLoaded = true;
      }
    },
  },
  created() {
    setTimeout(() => {
      this.carregarModulos();
    }, 500);
  },
};
</script>

<style scoped>
.card img {
  width: 100%;
  height: 250px;
}
</style>
